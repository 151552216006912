import * as React from 'react';
import { SVGProps } from 'react';
const SortIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M2.4 2.4a.8.8 0 0 0 0 1.6h8.8a.8.8 0 0 0 0-1.6H2.4ZM2.4 5.6a.8.8 0 0 0 0 1.6H8a.8.8 0 0 0 0-1.6H2.4ZM2.4 8.8a.8.8 0 0 0 0 1.6h3.2a.8.8 0 1 0 0-1.6H2.4ZM12 6.4a.8.8 0 0 0-1.6 0v4.469L9.366 9.834a.8.8 0 0 0-1.132 1.132l2.4 2.4a.8.8 0 0 0 1.132 0l2.4-2.4a.8.8 0 0 0-1.132-1.132L12 10.87V6.4Z"
    />
  </svg>
);
export default SortIcon;

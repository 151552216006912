import React from 'react';
import { LoginPage } from '@pages/Auth/login';
import { Authenticated, ErrorComponent } from '@refinedev/core';
import {
  CatchAllNavigate,
  NavigateToResource,
} from '@refinedev/react-router-v6';
import * as Sentry from '@sentry/react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { Register } from '@pages/register';
import { ForgotPasswordPage } from '@pages/Auth/forgotPassword';
import { ThemedLayoutV2 } from '../components/layout';
import { DashboardPage } from '@pages/dashboard';
import { ResetPasswordPage } from '@pages/Auth/resetPassword';
import { VerificationPage } from '@pages/Auth/Verification';
import { ContactUsList } from '@pages/contact-us';
import { NotificationCreate, NotificationList } from '@pages/notification';
import { ConsumerList, ConsumerShow } from '@pages/consumer';
import { AntdInferencer } from '@refinedev/inferencer/antd';
import PrivacyPolicy from '@pages/privacyPolicy';
import DeleteAccount from '@pages/deleteAccount';
const Stack = () => {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <SentryRoutes>
      <Route
        element={
          <Authenticated
            key="authenticated-inner"
            fallback={<CatchAllNavigate to="/login" />}
          >
            <ThemedLayoutV2>
              <Outlet />
            </ThemedLayoutV2>
          </Authenticated>
        }
      >
        <Route index element={<DashboardPage />} />

        {/* TODO Change used component after backend is ready */}
        <Route path="/trader-list">
          <Route index element={<AntdInferencer />} />
          <Route path="create" element={<AntdInferencer />} />
          <Route path="show/:id" element={<AntdInferencer />} />
        </Route>
        <Route path="/consumer-list">
          <Route index element={<ConsumerList />} />
          <Route path="show/:id" element={<ConsumerShow />} />
        </Route>
        <Route path="/notification-management">
          <Route index element={<NotificationList />} />
          <Route path="create" element={<NotificationCreate />} />
        </Route>
        <Route path="/contact-us">
          <Route index element={<ContactUsList />} />
        </Route>
        <Route path="*" element={<ErrorComponent />} />
      </Route>
      <Route
        element={
          <Authenticated key="authenticated-outer" fallback={<Outlet />}>
            <NavigateToResource />
          </Authenticated>
        }
      >
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/verification" element={<VerificationPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
      </Route>
    </SentryRoutes>
  );
};

export default Stack;

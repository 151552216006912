import React, { useEffect, useState } from 'react';
import PointsLineChart from '../../components/pointsLineChart';
import { Row, Typography } from 'antd';
import { colors } from '../../Theme/Colors';
import { DashBoardCard } from '../../components/dashBoardCard';
import { DatePickerPopUp } from '../../components/datePicker';
import { HomeData } from '../../types';
import { convertToArabicNumbers } from '../../Helpers';
import { LoadingOutlined } from '@ant-design/icons';
import { GetGraphDataAction } from '../../Actions/ConfigActions';
import dayjs from 'dayjs';

export const DashboardPage = () => {
  const [selectedDates, setSelectedDates] = useState<{
    fromDate: string | null;
    toDate: string | null;
  }>({
    fromDate: null,
    toDate: null,
  });
  const [graphData, setGraphData] = useState<HomeData | null>(null);

  const handleDateChange = (dates: Date[]) => {
    setSelectedDates({
      fromDate: dayjs(dates[0]).toISOString(),
      toDate: dayjs(dates[1]).toISOString(),
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedDates.fromDate && selectedDates.toDate) {
          const data = await GetGraphDataAction(
            selectedDates.fromDate,
            selectedDates.toDate
          );
          setGraphData(data as HomeData); // Type assertion
        } else {
          const data = await GetGraphDataAction();
          setGraphData(data as HomeData); // Type assertion
        }
      } catch (error) {
        return error;
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      // Cleanup code if necessary
    };
  }, [selectedDates]);

  return (
    <Row style={{ backgroundColor: 'white', gap: '3rem', padding: '3rem' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ gap: '1.5rem', display: 'flex', flexDirection: 'row' }}>
            <Typography.Text
              style={{
                fontSize: '1.5rem',
                fontWeight: 600,
                fontStyle: 'normal',
                color: colors.tableConten,
                lineHeight: 'normal',
              }}
            >
              النقاط المكتسبة
            </Typography.Text>
            <Typography.Text
              style={{
                color: colors.primaryColor,
                fontSize: '1.5rem',
                fontWeight: 600,
                fontStyle: 'normal',
                lineHeight: 'normal',
              }}
            >
              {graphData?.totalEarnedPoints !== undefined &&
              graphData.totalEarnedPoints !== null ? (
                convertToArabicNumbers(graphData?.totalEarnedPoints)
              ) : (
                <LoadingOutlined />
              )}
              نقطة
            </Typography.Text>
          </div>
          <DatePickerPopUp
            onDateChange={handleDateChange}
            datesObject={selectedDates}
          />
        </div>
        {graphData !== null && <PointsLineChart data={graphData?.data || []} />}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '3rem',
          width: '100%',
          flexWrap: 'nowrap',
          alignSelf: 'stretch',
        }}
      >
        <DashBoardCard
          value={
            graphData?.redemptionRate !== undefined &&
            graphData?.redemptionRate !== null ? (
              `${convertToArabicNumbers(graphData?.redemptionRate.replace('%', ''))}`
            ) : (
              <LoadingOutlined />
            )
          }
          percent
          title="معدل استبدال النقاط"
        />
        <DashBoardCard
          value={
            graphData?.consumers_count !== undefined &&
            graphData?.consumers_count !== null ? (
              `${convertToArabicNumbers(graphData?.consumers_count)}`
            ) : (
              <LoadingOutlined />
            )
          }
          title="مستهلك"
        />
      </div>
      {/* <DashBoardCard
        value={
          graphData?.consumers_count !== undefined &&
          graphData?.consumers_count !== null ? (
            `${convertToArabicNumbers(graphData?.consumers_count)}`
          ) : (
            <LoadingOutlined />
          )
        }
        title="المنتج الأكثر مسحًا ضوئيًا"
        width="100%"
      /> */}
    </Row>
  );
};

import React, { useState } from 'react';
import { Card, Input, Button, Modal, Form, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { apiRequest } from '../../rest-data-provider/utils/APIs';

const DeleteAccount: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const showConfirmModal = (phoneNumber: string) => {
    Modal.confirm({
      title: 'تأكيد حذف الحساب',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div className="text-right">
          <p>هل أنت متأكد من حذف الحساب برقم الهاتف:</p>
          <p className="font-bold">{phoneNumber}</p>
          <p className="text-red-600">
            تحذير: لا يمكن التراجع عن هذا الإجراء بعد التأكيد.
          </p>
        </div>
      ),
      okText: 'نعم، احذف الحساب',
      cancelText: 'إلغاء',
      okButtonProps: {
        className: 'bg-red-600 hover:bg-red-700',
        danger: true,
      },
      onOk: () => handleDeleteAccount(phoneNumber),
      direction: 'rtl',
    });
  };
  const handleDeleteAccount = async (phoneNumber: string) => {
    try {
      setLoading(true);
      await apiRequest({
        url: `/request-deletion?phoneNumber=${phoneNumber}&app_name=sipes`,
        method: 'GET',
      });

      message.success('تم حذف الحساب بنجاح');
      form.resetFields();
    } catch (error) {
      message.error('حدث خطأ أثناء حذف الحساب');
    } finally {
      setLoading(false);
    }
  };

  const onFinish = (values: { phone_number: string }) => {
    showConfirmModal(values.phone_number);
  };

  return (
    <div className="max-w-xl mx-auto py-8 px-4">
      <Card
        title="حذف الحساب"
        className="shadow-md"
        headStyle={{
          textAlign: 'right',
          fontSize: '1.5rem',
          borderBottom: '1px solid #f0f0f0',
        }}
      >
        <div className="space-y-6">
          <p className="text-right text-gray-600">
            الرجاء إدخال رقم الهاتف المرتبط بالحساب الذي تريد حذفه.
          </p>

          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="text-right"
          >
            <Form.Item
              name="phone_number"
              label="رقم الهاتف"
              rules={[
                { required: true, message: 'الرجاء إدخال رقم الهاتف' },
                {
                  pattern: /^[0-9]{10}$/,
                  message: 'الرجاء إدخال رقم هاتف صحيح (10 أرقام)',
                },
              ]}
            >
              <Input
                placeholder="أدخل رقم الهاتف"
                className="text-right"
                maxLength={10}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                danger
                htmlType="submit"
                loading={loading}
                className="w-full"
              >
                حذف الحساب
              </Button>
            </Form.Item>
          </Form>

          <div className="bg-gray-50 p-4 rounded-lg">
            <h4 className="text-right font-bold mb-2">ملاحظات مهمة:</h4>
            <ul className="text-right text-sm text-gray-600 space-y-2 list-disc list-inside">
              <li>سيتم حذف جميع البيانات المرتبطة بالحساب بشكل نهائي</li>
              <li>لا يمكن استعادة الحساب بعد الحذف</li>
              <li>سيتم إلغاء جميع النقاط والمكافآت المرتبطة بالحساب</li>
            </ul>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default DeleteAccount;

// data.ts

export const privacyPolicyArabic = {
  privacy: {
    title: 'سياسة الخصوصية',
    introduction: 'تحترم شركة سايبس خصوصيتك وتلتزم بحماية بياناتك الشخصية.',
    introduction_text:
      'توضح سياسة الخصوصية هذه كيفية التعامل مع بياناتك الشخصية عند استخدام تطبيقنا.',
    sections: [
      {
        title: 'معلومات هامة ومن نحن',
        purpose_title: 'الغرض من سياسة الخصوصية',
        purpose_text:
          'تهدف سياسة الخصوصية هذه إلى تزويدك بمعلومات حول كيفية جمع شركة سايبس لبياناتك الشخصية ومعالجتها من خلال استخدامك لهذا الموقع.',
        third_party_links_title: 'روابط الأطراف الثالثة',
        third_party_links_text:
          'يتضمن هذا التطبيق روابط لمواقع ويب تابعة لأطراف ثالثة. عند النقر فوق هذه الروابط، قد يتم جمع أو مشاركة بياناتك. نحن لا نتحكم في مواقع الطرف الثالث ولا نتحمل مسؤولية بيانات الخصوصية الخاصة بها.',
      },
      {
        title: 'البيانات التي نجمعها عنك',
        data_collect_intro:
          'البيانات الشخصية تعني أي معلومات عن فرد يمكن من خلالها التعرف عليه.',
        data: {
          identity_data: {
            title: 'البيانات الشخصية',
            description:
              'تتضمن بيانات الهوية: الاسم الأول، الاسم الأوسط، اسم العائلة، الهوية الوطنية، واسم المستخدم.',
          },
          contact_data: {
            title: 'بيانات الاتصال',
            description:
              'تتضمن بيانات الاتصال: البريد الإلكتروني وأرقام الهاتف.',
          },
          transaction_data: {
            title: 'بيانات المعاملات',
            description:
              'تتضمن بيانات المعاملات: تفاصيل المدفوعات والمنتجات والخدمات التي اشتريتها.',
          },
          technical_data: {
            title: 'البيانات التقنية',
            description:
              'تتضمن البيانات التقنية: عنوان بروتوكول الإنترنت (IP)، بيانات تسجيل الدخول، نوع المتصفح وإعدادات المنطقة الزمنية.',
          },
          profile_data: {
            title: 'بيانات الملف الشخصي',
            description:
              'تتضمن بيانات الملف الشخصي: اسم المستخدم وكلمة المرور، اهتماماتك وتفضيلاتك.',
          },
          usage_data: {
            title: 'بيانات الاستخدام',
            description:
              'تتضمن بيانات الاستخدام: معلومات حول كيفية استخدامك لتطبيقنا ومنتجاتنا وخدماتنا.',
          },
          marketing_data: {
            title: 'بيانات التسويق',
            description:
              'تتضمن بيانات التسويق: تفضيلاتك لتلقي التسويق من خلالنا أو من أطراف ثالثة.',
          },
        },
        no_special_data: {
          title: 'البيانات الخاصة',
          description:
            'نحن لا نجمع أي فئات خاصة من البيانات الشخصية عنك مثل العرق أو الديانة أو المعتقدات الفلسفية.',
        },
        not_providing_data: {
          title: 'إذا لم تقدم البيانات',
          description:
            'إذا كنت بحاجة لتقديم بيانات شخصية بموجب القانون، ولم تقدم البيانات المطلوبة، فقد لا نتمكن من تقديم الخدمة المطلوبة.',
        },
      },
      {
        title: 'كيف يتم جمع بياناتك الشخصية؟',
        how_data_collected_text:
          'نحن نستخدم عدة طرق لجمع البيانات منك أو عنك، بما في ذلك من خلال التفاعلات المباشرة والتقنيات المؤتمتة.',
        methods: {
          direct_interactions: {
            title: 'التفاعلات المباشرة',
            description:
              'يمكنك أن تقدم لنا بياناتك عن طريق ملء النماذج أو المراسلة عبر البريد أو الهاتف أو البريد الإلكتروني.',
          },
          automated_technologies: {
            title: 'التقنيات المؤتمتة',
            description:
              'نحن نقوم بجمع البيانات التقنية تلقائيًا أثناء تفاعلك مع تطبيقنا.',
          },
          third_party_sources: {
            title: 'مصادر الطرف الثالث',
            description:
              'قد نتلقى بيانات شخصية عنك من أطراف ثالثة مثل مقدمي خدمات الدفع أو التحليلات.',
          },
        },
      },
      {
        title: 'كيف نستخدم بياناتك الشخصية',
        how_use_data_text:
          'سنستخدم بياناتك الشخصية فقط عندما يسمح لنا القانون بذلك، وفي أغلب الأحيان نستخدمها لتنفيذ العقد معك أو الامتثال للالتزامات القانونية.',
        legal_basis: {
          title: 'الأسس القانونية لاستخدام بياناتك',
          description:
            'نستخدم بياناتك على أساس التزام قانوني، أو لتنفيذ عقد بيننا، أو لتحقيق مصالح مشروعة.',
          legitimate_interests: {
            title: 'المصالح المشروعة',
            description:
              'نستخدم بياناتك لتقديم خدماتنا وتحسينها بطريقة تخدم مصالحك ومصالحنا.',
          },
          contract_performance: {
            title: 'تنفيذ العقد',
            description:
              'نستخدم بياناتك لتنفيذ العقد الذي أبرمته معنا، مثل تقديم المنتجات أو الخدمات.',
          },
          comply_with_law: {
            title: 'الامتثال للقانون',
            description:
              'سنستخدم بياناتك الشخصية عندما يكون علينا الامتثال لالتزامات قانونية.',
          },
        },
        marketing: {
          title: 'التسويق',
          description:
            'سنقوم بتقديم الخيارات المتعلقة ببعض استخدامات بياناتك الشخصية وخاصةً التسويق والإعلان.',
          promotional_offers: {
            title: 'العروض الترويجية',
            description:
              'نستخدم بياناتك لتحديد العروض الترويجية التي قد تكون متوافقة مع اهتماماتك.',
          },
          third_party_marketing: {
            title: 'التسويق من طرف ثالث',
            description:
              'لن نشارك بياناتك الشخصية مع أي طرف ثالث لأغراض التسويق دون موافقتك الصريحة.',
          },
          opt_out: {
            title: 'إلغاء الاشتراك',
            description:
              'يمكنك إلغاء الاشتراك في تلقي الرسائل التسويقية في أي وقت عن طريق الاتصال بنا.',
          },
        },
        cookies: {
          title: 'ملفات تعريف الارتباط',
          description:
            'نستخدم ملفات تعريف الارتباط لتحسين تجربتك. يمكنك ضبط متصفحك لرفض ملفات تعريف الارتباط.',
        },
        change_of_purpose: {
          title: 'تغيير الغرض',
          description:
            'لن نستخدم بياناتك الشخصية إلا للأغراض التي جمعناها من أجلها، ما لم نحصل على موافقتك الصريحة لاستخدامها لغرض آخر.',
        },
        disclosures_of_your_data: {
          title: 'إفصاح بياناتك الشخصية',
          description:
            'قد نضطر إلى مشاركة بياناتك الشخصية مع أطراف خارجية لأغراض الامتثال القانوني أو تنفيذ العقد.',
        },
        data_security: {
          title: 'أمان البيانات',
          description:
            'لقد وضعنا تدابير أمان مناسبة لحماية بياناتك الشخصية من الوصول غير المصرح به.',
        },
        data_retention: {
          title: 'الاحتفاظ بالبيانات',
          description:
            'سنحتفظ ببياناتك الشخصية طالما كان ذلك ضروريًا لتحقيق الأغراض التي جمعناها من أجلها.',
        },
        your_rights: {
          title: 'حقوقك القانونية',
          description:
            'في بعض الحالات، لديك حقوق بموجب قوانين حماية البيانات مثل الوصول إلى بياناتك وتصحيحها أو حذفها.',
        },
        contact_us: {
          title: 'اتصل بنا',
          description:
            'إذا كان لديك أي أسئلة حول سياسة الخصوصية هذه، يرجى الاتصال بنا عبر البريد الإلكتروني: info@sipes.net',
        },
      },
    ],
  },
};

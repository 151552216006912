import React from 'react';
import { privacyPolicyArabic } from './data';
import { Typography, Card, Divider } from 'antd';

const { Title, Text, Paragraph } = Typography;

const PrivacyPolicy: React.FC = () => {
  const { privacy } = privacyPolicyArabic;

  return (
    <div className="max-w-4xl mx-auto py-8 px-4">
      <Card className="shadow-md">
        <Title level={1} className="text-center mb-8">
          {privacy.title}
        </Title>

        <Paragraph className="text-lg mb-6">{privacy.introduction}</Paragraph>

        <Text className="text-gray-600 block mb-8">
          {privacy.introduction_text}
        </Text>

        {privacy.sections.map((section, index) => (
          <div key={index} className="mb-12">
            <Title level={2} className="mb-6 text-primary">
              {section.title}
            </Title>

            {section.purpose_title && (
              <>
                <Title level={3} className="mb-4">
                  {section.purpose_title}
                </Title>
                <Paragraph className="mb-6">{section.purpose_text}</Paragraph>
              </>
            )}

            {section.data && (
              <div className="grid gap-6">
                {Object.entries(section.data).map(
                  ([key, item]: [string, any]) => (
                    <Card key={key} className="bg-gray-50">
                      <Title level={4} className="mb-2">
                        {item.title}
                      </Title>
                      <Text>{item.description}</Text>
                    </Card>
                  )
                )}
              </div>
            )}

            {section.methods && (
              <div className="grid gap-6">
                {Object.entries(section.methods).map(
                  ([key, item]: [string, any]) => (
                    <div key={key} className="mb-4">
                      <Title level={4} className="mb-2">
                        {item.title}
                      </Title>
                      <Text>{item.description}</Text>
                    </div>
                  )
                )}
              </div>
            )}

            {section.legal_basis && (
              <div className="mt-6">
                <Title level={3} className="mb-4">
                  {section.legal_basis.title}
                </Title>
                <Text className="block mb-4">
                  {section.legal_basis.description}
                </Text>
                <div className="grid gap-4">
                  {Object.entries(section.legal_basis)
                    .filter(([key]) => key !== 'title' && key !== 'description')
                    .map(([key, item]: [string, any]) => (
                      <Card key={key} className="bg-gray-50">
                        <Title level={4} className="mb-2">
                          {item.title}
                        </Title>
                        <Text>{item.description}</Text>
                      </Card>
                    ))}
                </div>
              </div>
            )}

            <Divider className="my-8" />
          </div>
        ))}

        <div className="bg-blue-50 p-6 rounded-lg mt-8">
          <Title level={3} className="text-primary mb-4">
            {privacy.sections[3]?.contact_us?.title}
          </Title>
          <Text className="text-lg">
            {privacy.sections[3]?.contact_us?.description}
          </Text>
        </div>
      </Card>
    </div>
  );
};

export default PrivacyPolicy;

import React, { useState } from 'react';
import { Button, Popover } from 'antd';
import { SortIcon } from '../../Assets/Svgs';

interface SortPopoverProps {
  // Add any props you need here
  handleSort: (sort: string) => void;
}

const SortContent = ({
  options,
  selectedOption,
  onOptionSelect,
}: {
  options: { label: string; value: string }[];
  selectedOption: string;
  onOptionSelect: (option: { label: string; value: string }) => void;
}) => (
  <div className="flex flex-col gap-2 min-w-[200px]">
    {options.map((option) => (
      <Button
        key={option.value}
        className={`border-none shadow-none w-full text-start hover:bg-gray-100 px-4 py-2
          ${selectedOption === option.value ? 'bg-[#F3F3F3]' : ''}`}
        onClick={() => onOptionSelect(option)}
      >
        <div className="w-full text-right">{option.label}</div>
      </Button>
    ))}
  </div>
);

export const SortPopover: React.FC<SortPopoverProps> = ({ handleSort }) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('الاسم');

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleOptionSelect = (option: { label: string; value: string }) => {
    setSelectedOption(option.label);
    setOpen(false);
    handleSort(option.value);
  };

  return (
    <Popover
      arrow={false}
      placement="bottom"
      open={open}
      overlayInnerStyle={{
        paddingLeft: 0,
        paddingRight: 0,
      }}
      content={
        <SortContent
          options={[
            { label: 'الاسم', value: 'name' },
            {
              label: 'النقاط: من الأكثر الى الأقل',
              value: 'points:desc',
            },
            {
              label: 'النقاط: من الأقل الى الأكثر',
              value: 'points:asc',
            },
          ]}
          selectedOption={selectedOption}
          onOptionSelect={handleOptionSelect}
        />
      }
      onOpenChange={handleOpenChange}
    >
      <Button
        icon={<SortIcon />}
        className="bg-[#F7F5FF] ms-3 h-10 rounded-xl px-4 py-2 border-none shadow"
      >
        الترتيب بواسطة: {selectedOption}
      </Button>
    </Popover>
  );
};
